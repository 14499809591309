// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URl: "https://abbott-ph-api-dev.sttarter.com/api",
  AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  API_BASE_URL:'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  SALESFORCE_BASE_URL:' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:'https://api.sttarter.com/contentsystem/d3eaf7a8067373ea08c47134748d7abf',
  API_URl_TRACEBILITY: "https://abbott-ph-api-dev.sttarter.com/api",
  IMAGE_UPLOAD:"https://apac-apigateway.capillarytech.com/mobile/v2/",
  EMAIL_CHECK_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
  // BRAND: 'ABBOTTDEMO',
  BRAND: 'ABBOTTALLDEMO',
  DEVICE_ID: '1234551231',
  IDENTIFIER_TYPE:"MOBILE",
  ACCOUNTID: "1657876634",
  BOT_PROMPT:'aggressive',
  STATE:'12345abcde',
  LINE_CONSENT_URL:'https://access.line.me/oauth2/v2.1/authorize?response_type=code',
  COUNTRY_CODE: 'TH',
  REDIRECT_URI:"https://abbott-th-uat.capillarytech.com/login",
  LANGUAGE: '',
  STTARTER_TERMS_CONDITION_ABOUT_FAQ: "https://api.sttarter.com/contentsystem/5481cc6d850acd866a5c0bd1544066a4",
  BUZZEEBEE_URL:"https://abbott.buzzebees-uat.com",
  //scanditKey:'AeIyRWPLQcWbButwqD9KhQMfM4mXN4cJwk2GMN1sH2COMyJVVmyM3GBAHVLNdZNs0DJhfedLtcTAeMuYqAwm8GcDalTOUVTVfWOGNiZa32zMcxFhU3NEvLVhoiacEpNLJD5JQiUK9bY0EsQ8QTLzlcdR8SmSdIBnj38Hp4ZsVrEMJ1+K9UEmd4Eg2NCGRxPoh31B9/NT9n9+QOM9Klh0QmRreQLlcn2uG0URVTVFWxdSXPHsS2lnFX5vf6FnYCzEH3T5WC1BtQwXY69Y8HB0bhVCe9KYKq2wQnYUupkmrab7OGTclRSOoM9cCNnTQcGKTk7gGKZ3E6iLWpvcGQ4u8useI+NeW5QYVXF4UAAuZ/+zdTUf503wOyhh7Hw5ef+wEUUPUd4BrDiOXe/SHETsm8Ju6FQBF1qOz01i3gBrYJwbfk3M7ixl6ikhczIqAYDpADayGnVzFO1medmUFRkt3rBFbZ6DeoyQvRPCFh4gpb08odCXV3PihZalwjlc9mm906DQLUefXWQJZpGmEpnPRa6MD1JiD8yQxnfClyV4PyoAYIjFpj4e9cBBI6xzFXj2BRNIZYAqqVmxwJT8pWO/gPIUpP/ZbwjWDXUOx9DO28uLkCl/tkyhAdaxwfnjXJqXRhuw3skdfVjLoYGhGun7jv5nUGStarpIOVvzuRa24MolV8OAig0WjcaWnfYFGUdANFeLGR4TA7ZAR8ubTcWhQ7WKT7RPRLXAKM4ZLFW6iNJUCE8i8pJpwa+wNizA/X5zyWU9BqauteJlZIoVxSy8POUDy+wmgIo9x7KoRgVDvFR2VLIL1C+Mn55gwSVGcrtD9QS0YZfGsBjcvITOXY46nEj/+X4mU8h5Z3LeZFJO8YnHuQDOK+cbOl7IcN8pQiLU7Ku1BS0Etm62mF4JFjGpPIAl7ZigXWH9a6w0/9ZFspb5iiUE0jkTatWI6WOoIfT75Kiy2yG2aoEX7QoWfMufVt8aMoIOB2ReF5uoZ+3ONNXRSbhMu4XtdMImNNBvpgI68mnOAkh5ZntZXM6h4NTMiS3M3892h+6xiuwmeKAo0mqQ2Qu+wkfa+91vi6Er6hZ1Os8TNSQ6qfiJDvFIp5PvNThjpu0QH4MVnJKk8KtQIbx/mpRex/lL4DuQcGPqxPB/nWorbO1YnsJ9mtnSbUS6kA==',
  scanditKey:'AQ7ydSTfBhHsPMwycQwa+vMNI7nFMbWetFowcNRFNqARJW2kmFDyaJgB5c9XRKuJ5gJfjd1+cNYkDa+53lal49MtnIIxT+s7mlexueNwCgG9XFAxhH7Xcutk1iThc61JuUt/Ra9mbFzxfdrxsmnR5hBJeUO6CGcJRlP84/RsXKYNQoOo/VK60PUXGjyoG5Ve7RZ9Y/oZCl9SG3OKGhoSjbhsOKZ0jip2DggXGbq96t9A5AHLAQVrjpqmC0DVXfpTcg/qd+KN5B/h2n9Nfb9VV4dPgEpg0ooBcouGDuTRKiVZLXjjLYfDZHkfr/rmCanhd3zJU7bbs5ahqzOc6sDx3Uug3c3Khitpe/nVjcLksLAZvA05ccoGx8cM2P0qvPacdBPBLYcGeefeTQBreF0EXD7DaHHFQ3zbGoXaBHqS0KoKadIG9cZlTWVbVVV+9GPW3MgzkaH84Zx4lqqXpJCilvL3fbKp0Y3JGSCwlm03Ix2p8wDOtYoMwNbnfRd44wmLlPGecAQwkarMWM9YZFYgaw0eXcMg0SSHxoOmMnyKIBMKUXNaxJ4t+yOiHVybu2g6seKpgTWPmgg4FnnXdUc0otRuiEDGgTbi5wlpGkL3sUBElXJSogINy2xoCPobvC1fZuiMxSJQdUGK4jJH0P6GU4IbLNdGBkxR1gydjxC9gvo2DYqv32smMrcdaZFKZRsUWys0w+17ZWKKbNwkxiS2w9in3rpjgJpZ5NiTfvt9DMOcGKJrXTrpifULyGOklnV7wDO/LOBLmbNPpoNjOlp+c3PsB5rDz9Z3JSDDQMUENY83MUMH+y9j7nQ5OyFaXBWlicVfR0zWAxHjVJnGD2ug6BZO5OBC1gqUU89HVH+qP6X2UInmFKRLhRMD7uKWiQ==',
  LIFFID:'1657876634-GpzDonzm',
  LIFF_URL:`https://liff.line.me/1657876634-GpzDonzm`,
  PRODUCT_IMAGES:
  'https://api.sttarter.com/contentsystem/5481cc6d850acd866a5c0bd1544066a4',
  sfproductList : [
    {
      sku:'100S434233440',
      sfcode:'S434.140FE'
    },
    {
      sku:'100S434233485',
      sfcode:'S434.101FE'
    },
    {
      sku:'100S434233340',
      sfcode:'S434.140FD'
    },
    {
      sku:'100S434233385',
      sfcode:'S434.101FD'
    },
    {
      sku:'100S616233285',
      sfcode:'S616.101F7'
    },
    {
      sku:'100S616233340',
      sfcode:'S616.140F9'
    },
    {
      sku:'100S842233240',
      sfcode:'S842.140F9'
    },
    {
      sku:'100S842233285',
      sfcode:'S842.101F5'
    },

    
  ],
  bugsnag_version: '11032024',
  MOBILE_APP_KEY: "85a4447a3b438fbb6e33e5c0428460d3"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

