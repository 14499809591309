import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import jsQR from 'jsqr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from '../services/api.service';
import { environment as env } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from '../services/bugsnag.service';
import { ChangeDetectorRef } from '@angular/core';
declare var ZXing: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  showScanner: boolean = false;
  showProfile: Boolean = true;
  videoStart = false;
  scanCode: any;
  userPoints: any;
  categoryCode: any;
  checkQRCode2: any;
  scancodeData: any;
  pointsEarned: any;
  user: any;
  tabId = 'ensure-tab';
  iSprintData: any;
  image: any;
  customerName: any;
  latitude: any;
  longitude: any;
  confidence: any;
  prodImage: any;
  suspicious_count: any = 0;
  customer: any;
  customerData: any;
  weight: any;
  isWeightEmpty: boolean;
  sfCode: any;
  isNotChecked: any;
  isprintPoint: any;
  gpasSku: any;
  gpasPoints: any;
  catCode: any;


  @ViewChild('video', { static: true }) videoElm: ElementRef;
  @ViewChild('canvas', { static: true }) canvasElm: ElementRef;
  @ViewChild('successScanModal', { static: true })
  successScanModal: ModalDirective;

  @ViewChild('gpasSuccessMdl', { static: true })
  gpasSuccessMdl: ModalDirective;
  @ViewChild('failedScanModal', { static: true })
  failedScanModal: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;
  @ViewChild('popupModalOne', { static: true }) popupModalOne: ModalDirective;
  @ViewChild('camerapermission', { static: true }) camerapermission: ModalDirective;
  @ViewChild('popupModal', { static: true }) popupModal: ModalDirective;
  @ViewChild('announcementnModal', { static: true }) announcementnModal: ModalDirective;
  gpasprodimgres: any;
  gpasprodinforesp: any;
  videodevices: any;
  getcustresp: Object;
  isprintresp: any;
  gpasresp: any;
  prodimage: any;
  public scannerReady = false;
  public showButton = false;
  public showDescription = true;
  public result = "";

  medias: MediaStreamConstraints = {
    audio: false,
    video: false,
  };
  scannedCodeURL: any;
  scannedCode: any;
  lineUserEmail: any;
  lineUserMobile: any;
  lineUserCUID: any;
  loginCUID: string;
  userID: any;
  lineURLCuid: string;
  lineDetailsEmail: any;
  lineDetailsCUID: any;
  lineDetailsMobile: any;
  email: any;
  loader: boolean;
  isQRScannedFrmCam: string;
  findparams:any;

  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private bsService: BugsnagService, private cd: ChangeDetectorRef
  ) {
    this.getCustomer();
    this.findparams = this.route.snapshot.paramMap.get('popup');
    console.log(this.findparams);

    let scanCodeData = sessionStorage.getItem('iSprintData');
    if (scanCodeData != null) {
      let iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
      this.categoryCode = iSprintData?.product?.categoryCode;
      console.log(this.categoryCode, 'iSprintData==========');
      this.scanCode = sessionStorage.getItem('scanCode');
      this.checkQRCode2 = iSprintData?.uaidInfo?.type;
      this.weight = this.iSprintData?.size;
      this.isWeightEmpty = this.isEmpty(this.weight);
      if (this.isWeightEmpty) {
        this.weight = this.weight.includes('GRS')
          ? this.weight.replace('GRS', '')
          : this.weight.replace('GR', '');
        if (this.weight.includes('X')) {
          let weightArray = this.weight.split('X');
          let noOfItems = weightArray[0];
          let quantity = weightArray[1];
          console.log(noOfItems, 'noOfItems', quantity, 'quantity');
          this.weight = (parseInt(quantity) * noOfItems) / 1000;
        } else {
          this.weight = parseInt(this.weight) / 1000;
        }
      } else {
        this.weight = 0;
      }
      console.log('weight: ', this.weight);
      this.weight = JSON.stringify(this.weight);
      sessionStorage.setItem('productWeight', this.weight);
      this.spinner.show();
      this.apiService.getGpasProductImage(this.iSprintData?.sku).subscribe(
        (res: any) => {
          this.gpasprodimgres = res;
          this.spinner.hide();
          this.prodImage = res.data[0]?.productimage;
          console.log(this.prodImage, 'getGpasProductImage');
        },
        (err) => {
          this.bsService.notifyError(err);
          console.log(err);
          this.spinner.hide();
        }
      );
    }
  }

  ngOnInit(): void {
  //getDatesbetween for announcements
  const currentDate = new Date(); // Current date
  const endDate = new Date('2024-03-29'); // End date
  const datesBetween = this.getDatesBetween(currentDate, endDate);
  console.log(datesBetween, 'announcement popup');
  if(datesBetween.length > 0 && this.findparams=='true'){
    this.announcementnModal.show(); 
   }

    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.popupModalOne.hide();
    console.log(sessionStorage.getItem('isLineUser'), 'isLineUser')
    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
    this.scanCode = sessionStorage.getItem('scanCode');
    this.gpasSku = this.iSprintData?.sku;
    this.isQRScannedFrmCam = sessionStorage.getItem('isQRScannedFrmCam')
    //this.getCustomer()
    console.log('this.isQRScannedFrmCam', this.isQRScannedFrmCam)
    if (sessionStorage.getItem('isLineUser') == 'false') {
      if (sessionStorage.getItem('hasToExecuteLineAPI') == 'true')
        this.getLineDetails()
      console.log('scanCode', this.scanCode)
      console.log("isQRScanned: ",sessionStorage.getItem('isQRScanned'))
    }

    if (sessionStorage.getItem('isQRScannedFrmCam') == 'true') {
      //if scanned from camera on home page Gpass, iSprint hidden and exposed
      this.getPosition()
      console.log('scanCode', this.scanCode)
      console.log("isQRScanned: ",sessionStorage.getItem('isQRScanned'))
      sessionStorage.setItem('isQRScannedFrmCam', 'false');
    } else {
              // incase if isLineUser is true and scanned the qrcode via liff app
              //incase  if not scanned from camera on home page Gpass, iSprint hidden and exposed
  console.log('scanCode', this.scanCode)
  console.log("isQRScanned: ",sessionStorage.getItem('isQRScanned'))
      this.spinner.show()
      console.log('lat,long,confidence', this.latitude, this.longitude, this.confidence)
      console.log('scanCode', this.scanCode)
      console.log("isQRScanned: ",sessionStorage.getItem('isQRScanned'))
        if (sessionStorage.getItem('isQRScanned') == 'true' && this.isScancode(this.scanCode)) {
         this.getPosition();
        }

    }
  }

  getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  isScancode(code) {
    return code == 'null' || code == undefined || code == '' ? false : true
  }

  addLineAccount(auth, mobile, email) {
    let formData = {
      "loyaltyInfo": {
        "loyaltyType": "loyalty"
      },
      "profiles": [
        {
          "identifiers": [
            {
              "type": "cuid",
              "value": this.lineURLCuid
            },
            {
              "type": "mobile",
              "value": mobile
            },
            {
              "type": "email",
              "value": email
            }
          ],
          "source": "LINE",
          "accountId": env.ACCOUNTID
        }
      ]
    }
    this.spinner.show()
    this.apiService.addLineAccount(auth, mobile, formData).subscribe((res: any) => {
      this.spinner.hide()
      console.log('addlineaccount', res)
      if (res?.['createdId']) console.log(res, "added line account successfully")
    }, err => {
      this.bsService.notifyError(err);
      this.spinner.hide()
      console.log(err)

    })
  }

  getLineDetails() {
    let auth = sessionStorage.getItem('authToken')
    let mobile = sessionStorage.getItem('mobileNo')
    this.lineURLCuid = sessionStorage.getItem('lineURLCuid')
    let email = sessionStorage.getItem('email')
    this.spinner.show()
    sessionStorage.setItem('hasToExecuteLineAPI', 'false')
    this.apiService.getLineDetails(auth, mobile).subscribe((res: any) => {
      this.spinner.hide()
      // if user doesnt exist, add line user details
      console.log('getlineDetails', res)
      if (res?.errors?.[0]?.code == '8015') {
        // call post add line account api
        this.addLineAccount(auth, mobile, email)
      } else {
        this.spinner.hide()
        let lineDetailsRes = res
        let lineDetail = lineDetailsRes['profiles'][0]['identifiers']
        this.userID = lineDetailsRes['profiles']['0']['userId']
        lineDetail.forEach(detail => {
          if (detail.type == 'email') this.lineDetailsEmail = detail['value']
          if (detail.type == "cuid") this.lineDetailsCUID = detail['value']
          if (detail.type == "mobile") this.lineDetailsMobile = detail['value']

        })

        // if returns line id
        // comapare the line id and update if its different
        if (this.lineURLCuid != this.lineDetailsCUID) {
          this.updateLineID(auth, mobile, this.userID)
        }
      }
    }, err => {
      this.bsService.notifyError(err);
      this.spinner.hide()
      console.log(err)
    })
  }

  updateLineID(auth, mobile, userID) {
    let formData = {
      "add": [
        {
          "type": "cuid",
          "value": this.lineURLCuid
        }
      ],
      "remove": [
        {
          "type": "cuid",
          "value": this.lineDetailsCUID
        }
      ]
    }
    this.spinner.show()
    this.apiService.updateLineID(auth, mobile, this.userID, formData).subscribe((res: any) => {
      this.spinner.hide()
      console.log('updatelineid', res)
      if (res?.['createdId']) console.log('Line ID updated successfully')
    }, err => {
      this.bsService.notifyError(err);
      this.spinner.hide()
      console.log(err);
    })
  }



  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }
  ontabChange(ids: any) {
    this.tabId = ids;
  }

  itemsPerSlide = 3;
  singleSlideOffset = true;
  noWrap = true;

  slides = [
    { image: 'assets/images/pdc_van_370g.png' },
    { image: 'assets/images/pdc_van_740g.png' },
    { image: 'assets/images/pdc_van_1480g.png' },
    { image: 'assets/images/pdc_van_370g.png' },
    { image: 'assets/images/pdc_van_740g.png' },
    { image: 'assets/images/pdc_van_1480g.png' },
  ];




  onClickScan() {
    this.router.navigate(['/scanner'])
  }

  redirectToTracebility(result: any) {
    let cat_code = result.category_code;
    if (result?.is_isprint == false) {
      if (
        (cat_code.startsWith('Ensure') ||
          cat_code.startsWith('ENSURE') ||
          cat_code.startsWith('ensure')) &&
        (result.sku == '100S616233385' ||
          result.sku == '100S842233385' ||
          result.sku == '100S617133385' ||
          result.sku == '100S616233440' ||
          result.sku == '100S842233340')
      ) {
        this.router.navigate(['/ensureh-traceability']);
      } else if (
        (cat_code.startsWith('Ensure') ||
          cat_code.startsWith('ENSURE') ||
          cat_code.startsWith('ensure')) &&
        (result.sku == '100S907233140' ||
          result.sku == '100S907233185' ||
          result.sku == '100S956233140' ||
          result.sku == '100S956233185' ||
          result.sku == '100Y483833185' ||
          result.sku == '100Y483833140' ||
          result.sku == '100Y484833140' ||
          result.sku == '100Y511233140' ||
          result.sku == '100S907233240' ||
          result.sku == '100S956233240')
      ) {
        this.router.navigate(['/ensuregold-traceability']);
      } else if (
        (cat_code.startsWith('Ensure') ||
          cat_code.startsWith('ENSURE') ||
          cat_code.startsWith('ensure')) &&
        (result.sku == '100Y576233140' ||
          result.sku == '100Y576233185')
      ) {
        this.router.navigate(['/ensureplantbased-home']);
      }
      else if (

        cat_code.startsWith('PediaSure') ||
        cat_code.startsWith('Pediasure') ||
        cat_code.startsWith('pediasure') ||
        cat_code.startsWith('PEDIASURE') &&
        result.sku == '100S792233240'

      ) {
        this.router.navigate(['/traceability-home']);
      }
      else if (
        (cat_code.startsWith('Glucerna') ||
          cat_code.startsWith('GLUCERNA') ||
          cat_code.startsWith('glucerna')) &&
        (result.sku == '100Y524833140' ||
          result.sku == '100Y524833185')
      ) {
        this.router.navigate(['/glucernaplus-traceability']);
      }
      else if (
        cat_code.startsWith('Ensure') ||
        cat_code.startsWith('ENSURE') ||
        cat_code.startsWith('ensure')
      ) {
        this.router.navigate(['/ensure-traceability']);
      } else if (
        cat_code.startsWith('Glucerna') ||
        cat_code.startsWith('GLUCERNA') ||
        cat_code.startsWith('glucerna')
      ) {
        this.router.navigate(['/glucerna-traceability']);
      } else if (
        cat_code.startsWith('PediaSure') ||
        cat_code.startsWith('Pediasure') ||
        cat_code.startsWith('pediasure') ||
        cat_code.startsWith('PEDIASURE')
      ) {
        this.router.navigate(['/traceability-home']);
      }
    } else if (result?.is_isprint == true) {
      if (
        (result?.category_code == 'Ensure Gold')) {
        this.router.navigate(['/ensuregold-traceability']);
        return;
      }
      if (

        result?.category_code == 'Ensure POP H'
      ) {
        this.router.navigate(['/ensureh-traceability']);
        return;
      }
      if (

        result?.category_code == 'Glucerna Plus'
      ) {
        this.router.navigate(['/glucernaplus-traceability']);
        return;
      }

      else if (

        result?.category_code == 'Pediasure Plus'
      ) {
        this.router.navigate(['/pediasureplus-traceability']);
        return;
      } else if (
        cat_code.startsWith('Ensure') ||
        cat_code.startsWith('ENSURE') ||
        cat_code.startsWith('ensure')
      ) {
        this.router.navigate(['/ensure-traceability']);
      } else if (
        cat_code.startsWith('Glucerna') ||
        cat_code.startsWith('GLUCERNA') ||
        cat_code.startsWith('glucerna')
      ) {
        this.router.navigate(['/glucerna-traceability']);
      } else if (
        cat_code.startsWith('PediaSure') ||
        cat_code.startsWith('Pediasure') ||
        cat_code.startsWith('pediasure') ||
        cat_code.startsWith('PEDIASURE')
      ) {
        this.router.navigate(['/traceability-home']);
      }
    }
  }

  getProductInfo() {
    this.loader = true;
    this.spinner.show();
    sessionStorage.setItem('isQRScanned', 'false')
    console.log('getProductInfo', this.scanCode)
    if (this.scanCode == 'xyz') {
      this.spinner.hide();
      //No need to do anything if dummy code 'xyz' is used. This is to make lineconsent work
    } else {
      this.spinner.show()
     // alert('home'+this.scanCode+this.latitude+this.longitude+this.confidence)
      this.apiService
        .productinfo(
          this.scanCode,
          this.latitude,
          this.longitude,
          this.confidence
        )
        .subscribe(
          (res: any) => {
            this.loader = false;
            console.log(res, 'res isprint');
            if (res.status === 'success') {
              this.spinner.hide()
              console.log('getProductInfo', res)
              sessionStorage.setItem('iSprintData', JSON.stringify(res.result));
              let cat_code = res.result.category_code;
              sessionStorage.setItem('catCode', cat_code);
              if (res.result.uaid_info_type == 1) {
                this.redirectToTracebility(res.result)
              } else {
                this.onClickCancelModalone()
              }
            } else {
              this.spinner.hide()
              alert('Please scan a valid QR code.');
            }
          },
          (err) => {
            this.spinner.hide()
            console.log(err.error.message);
            alert(err.error.message);
            this.loader = false;
            this.spinner.hide()
            this.bsService.notifyError(err);
          }
        );
    }
  }


  onClickScanner(result: any) {
    this.popupModalOne.hide();
    this.showScanner = true;
    this.showProfile = false;

    let selectedDeviceId;
    if (result) {
      let data = result;
      console.log(data, 'scanned data===');
      let array = data.split('=');
      let scannedCode = array[1];
      this.scanCode = scannedCode;
      console.log(this.scanCode, ' scannedCode');
      sessionStorage.setItem('scanCode', this.scanCode);
      let scanCode = sessionStorage.getItem('scanCode');
      console.log(scanCode, 'scanCode first');
      this.spinner.show();
      this.getProductInfo()

    }
  }

  closeScanner() {
    this.showScanner = false;
  }

  onClickSuccessScanModal() {
    this.successScanModal.show();
  }
  closeSuccessScanModal() {
    this.successScanModal.hide();
    this.router.navigate(['/home']);
  }
  closeGpasSuccessScanModal() {
    this.gpasSuccessMdl.hide();
    this.router.navigate(['/home']);
  }
  onClickFailedScanModal() {
    this.failedScanModal.show();
  }
  closeFailedScanModal() {
    this.failedScanModal.hide();
  }
  closeAnnouncementMdl() {
    this.announcementnModal.hide();
  }
  closeExceedLimitModal() {
    this.exceedLimitModal.hide();
    this.router.navigate(['/home']);
  }
  parseFunction(value) {
    return JSON.parse(value);
  }
  getPosition() {
    this.spinner.show()
    this.apiService
      .getPosition()
      .then((pos) => {
        console.log('getPosition')
        let myuuid = uuidv4();
        let loggerFormData = {
          unique_id: myuuid,
          message: JSON.stringify(pos),
        };
        this.spinner.hide()
        let lat = pos.lat;
        let lang = pos.lng;
        let confidence = pos.accuracy;
        this.latitude = JSON.stringify(lat);
        this.longitude = JSON.stringify(lang);
        this.confidence = JSON.stringify(confidence);
        sessionStorage.setItem('latitude', this.latitude)
      sessionStorage.setItem('longitude', this.longitude)
      sessionStorage.setItem('confidence', this.confidence)
        let mobile = sessionStorage.getItem('mobileNo');
        console.log('lat,long,conf', this.latitude, this.longitude, this.confidence)
        let token = sessionStorage.getItem('authToken');
        this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
          this.customerData = res['customers']['customer'][0];
          this.customerName =
            this.customerData.firstname + ' ' + this.customerData.lastname;
          sessionStorage.setItem('customerName', this.customerName)
          this.getProductInfo()
        })

      })
      .catch((err) => {
        this.bsService.notifyError(err);
      });
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    this.spinner.show()
    this.apiService.getCapCustomer(mobile, token).subscribe(
      (res) => {
        this.getcustresp = res;
        console.log(res, 'res---');
        this.spinner.hide()
        console.log(res['customers']?.['customer'][0].user_id, 'used id---');
        sessionStorage.setItem(
          'abbottUserId',
          res['customers']['customer'][0].user_id
        );
        this.user = res['customers']['customer'][0];
        this.userPoints = res['customers']['customer'][0]?.loyalty_points
          ? parseInt(res['customers']['customer'][0]?.loyalty_points)
          : 0;
        this.cd.detectChanges();
        console.log(this.userPoints, 'this.userPoints');
        let lifetimepoints = res['customers']['customer'][0].lifetime_purchases;
        console.log(this.userPoints, 'this.userPoints');
        console.log(lifetimepoints, 'lifetimepoints');
        this.customerData = res['customers']['customer'][0];
        this.customerName =
          this.customerData.firstname + ' ' + this.customerData.lastname;
        sessionStorage.setItem('customerName', this.customerName)
        this.email = this.customerData['email']
        sessionStorage.setItem('email', this.email)
        this.customerData.custom_fields.field.forEach((element) => {
          if (element.name === 'suspicious_count') {
            this.suspicious_count = parseInt(element.value);
          }
        });
      },
      (err) => {
        this.bsService.notifyError(err);
        this.spinner.hide();
        console.log(err, 'err');
      }
    );
  }
  onClickCancelModalone() {
    console.log('onClickCanelModalone')
    this.iSprintData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(this.iSprintData?.sku, 'isprint sku');
    this.customerName = sessionStorage.getItem('customerName')
    let mobile = sessionStorage.getItem('mobileNo');
    let scanCode = sessionStorage.getItem('scanCode');
    let token = sessionStorage.getItem('authToken');
    console.log(this.iSprintData?.is_isprint);
    if (this.iSprintData?.is_isprint) {
      console.log('image: ', this.image);
      let parsedPrimaryValue = this.parseFunction(
        this.iSprintData?.product_name
      ).en_US;
      let prdname = this.iSprintData?.crm_name?.th_TH;
      let formData = {
        scan_code: scanCode.trim(),
        mobile: mobile,
        cap_device_id: env.DEVICE_ID,
        customer_name: this.customerName,
        suspicious_count: this.suspicious_count,
        product_name: prdname,
        product_image: this.iSprintData?.product_image,
        confidence: this.confidence,
        product_type: this.iSprintData?.category_code,
        custom_fields: [
          {
            name: 'translat',
            value: this.latitude,
          },
          {
            name: 'translong',
            value: this.longitude,
          },
          {
            name: 'productname',
            value: prdname ? prdname : 'Pediasure',
          },
          {
            name: 'categorycode',
            value: prdname ? prdname : 'PediaSure',
          },
          {
            name: 'sfproductcode',
            value: this.iSprintData.sf_product_code
              ? this.iSprintData.sf_product_code
              : 'Null',
          },
        ],
      };
      this.spinner.show();
      console.log(formData, 'form data');
      
      this.apiService
        .postGpassEarnPoints(formData, token)
        .pipe(first())
        .subscribe(
          (res: any) => {
            console.log('postGpassEarnPoints', res)
            this.isprintresp = res;
            this.spinner.hide();
            if (res['status'] == 'success') {
              this.spinner.hide();
              this.closeScanner();
              console.log(res, 'resp on isprint earns points....');
              this.isprintPoint = res?.result?.points;
              this.getCustomer()
              this.successScanModal.show();
            } else {
              // add transcation fail show the pop-up
              this.closeScanner();
            }
          },
          (err) => {
            sessionStorage.removeItem('iSprintData');
            this.bsService.notifyError(err, formData);

            this.spinner.hide();
            this.closeScanner();
            console.log(err);
            if (err.status == 400) {
              if ((err.error.message = 'Given code already verified')) {
                console.log(err.error.message);
                this.onClickFailedScanModal();
              }
              console.log(err);
              console.log(err.response?.message, 'Payload maybe wrong');
            }

            //403 :TODO //This product doesn't belong to this company.
            //502 :TODO //Something went wrong, please try again later.

            if (err.status == 420) {
              console.log(err);
              this.exceedLimitModal.show();
            }
          }
        );
     
    } else {
      console.log(env.sfproductList, ' env.sfproductList');
      console.log(this.iSprintData?.sku, 'this.iSprintData.sku');
      this.spinner.show();
      this.apiService.getGpasProductImage(this.iSprintData?.sku).subscribe(
        (res: any) => {
          this.prodimage = res;
          this.spinner.hide();
          console.log(res.data, 'getGpasProductImage');
          console.log(this.latitude, this.longitude, this.confidence);
          this.prodImage = res.data[0]?.image;
          let formData = {
            scan_code: scanCode?.trim(),
            mobile: mobile,
            customer_name: this.customerName,
            suspicious_count: this.suspicious_count,
            product_name: this.iSprintData?.thai_product_name,
            product_image: this.prodImage ? this.prodImage : 'null',
            latitude: this.latitude,
            longitude: this.longitude,
            confidence: this.confidence,
            product_type: this.iSprintData?.category_code,
            description: this.iSprintData?.product_description,
            custom_fields: [
              {
                name: 'productname',
                value: this.iSprintData?.thai_product_name,
              },
              {
                name: 'categorycode',
                value: this.iSprintData?.brand,
              },
              {
                name: 'sfproductcode',
                value: this.iSprintData?.product_code,
              },
            ],
          };

          console.log(formData, 'gpas form data');
          this.spinner.show();
          let country = this.iSprintData?.country?.toLowerCase().trim() || this.iSprintData?.country_of_origin?.toLowerCase().trim()
          if (country == 'thailand') {
          this.apiService
            .postGpassEarnPoints(formData, token)
            .pipe(first())
            .subscribe(
              (res: any) => {
                sessionStorage.removeItem('iSprintData');
                this.gpasresp = res;
                this.spinner.hide();
                console.log('res: ', res);
                if (res['status'] == 'success') {
                  this.spinner.hide();
                  this.closeScanner();
                  this.apiService
                    .getSkuPoints(this.iSprintData.sku)
                    .subscribe((res: any) => {
                      console.log(res?.data[0]?.points, 'gpaspoints');
                      this.gpasPoints = res?.data[0]?.points;
                    });
                  console.log(res, 'resp on GPAS earns points....');
                  this.getCustomer()
                  this.gpasSuccessMdl.show();
                } else {
                  // add transcation fail show the pop-up
                  this.closeScanner();
                }
              },
              (err) => {
                sessionStorage.removeItem('iSprintData');
                this.bsService.notifyError(err, formData);
                this.closeScanner();
                this.spinner.hide();
                if (err.status == 400) {
                  this.onClickFailedScanModal();
                }

                if (err.status == 420) {
                  this.exceedLimitModal.show();
                }
              }
            );
          }else{
              // show popup  invalid qr code
              this.spinner.hide()
              this.popupModal.show()
          }
        },
        (err) => {
          sessionStorage.removeItem('iSprintData');
          this.bsService.notifyError(err);
          this.spinner.hide();
          console.log(err, 'err');
        }
      );
    }
  }

  checkValue(value) {
    console.log(value);
    sessionStorage.setItem('checkbox', value);
  }

  onclickScanToCollect() {
    let check = sessionStorage.getItem('checkbox');
    console.log(check);
    if (check !== 'isChecked') {
      this.popupModalOne.show();
    } else {
      this.showScanner = true;
      this.showProfile = false;

      if (this.showScanner) {
        this.router.navigate(['/scanner'])
      }
    }
  }

  onClickOK() {
    this.popupModal.hide()
  }
}

